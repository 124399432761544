<template>
    <login-template>
        <notification :message="message"></notification>
        <div>
            <h2 class="mt-8 text-2xl font-bold leading-9 tracking-tight text-v3-gray-800 dark:text-v3-gray-200">Forgot password?</h2>
            <p class="mt-2 text-sm leading-6 text-v3-gray-600 dark:text-v3-gray-400">
                No worries! We'll send you rest instructions.
            </p>
        </div>

        <div class="mt-10">
            <div>
                <p class="text-red-600" v-if="error" v-text="error"></p>
                <form action="#" method="POST" class="space-y-6" @submit.prevent="onSubmit">
                    <div>
                        <label for="email" class="block text-sm font-medium leading-6 text-v3-gray-800 dark:text-v3-gray-200">Email</label>
                        <div class="mt-2">
                            <input id="email" placeholder="Email" name="email" type="text" autocomplete="email" class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-inset ring-gray-300 placeholder:text-v3-gray-200 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-v3-gray-550 dark:placeholder-v3-gray-700 dark:bg-secondary-appgray dark:text-v3-gray-200"
                            v-model="email"
                            v-validate="'required'"
                           :class="{'is-invalid': errors.first('email')}">
                        </div>
                    </div>
                    <span class="mt-2 text-sm text-red-600">{{ errors.first('email') }}</span>

                    <div>
                        <button type="submit" class="flex w-full justify-center rounded-md bg-v3-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-v3-gray-600 shadow-sm hover:bg-v3-gray-300 focus:outline-none items-center dark:bg-v3-gray-800 dark:text-white dark:hover:bg-v3-gray-600">
                            Send Link
                            <loading v-if="loading" class="inline-flex -m-6 relative h-auto -ml-4 align-middle" color-class="text-v3-gray-600 dark:text-white"></loading>
                        </button>
                    </div>

                    <p class="mt-10 text-center text-sm text-v3-gray-600 dark:text-v3-gray-400">
                        Nevermind?
                        <a href="#" @click.prevent="goToLogin();" class="cursor-pointer font-semibold leading-6 hover:text-v3-gray-50 hover:opacity-50">Sign In</a>
                    </p>
                </form>
            </div>
        </div>
    </login-template>
</template>

<script>
import axios from "axios";
import LoginTemplate from "@/v3/templates/LoginTemplate.vue";
import Loading from "@/components/Loading.vue";
import {mapGetters} from "vuex";
import Notification from "@/components/Notification.vue";
import Siren from "super-siren";
export default {
    components: {Loading, Notification, LoginTemplate},
    data() {
        return {
            error: null,
            email: null,
            loading: false
        };
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            message: "getMessage",
        })
    },
    mounted() {
        if (this.accessToken !== null) {
            Siren.Client.addHeader("Authorization", `Bearer ${this.accessToken}`);
            return Siren.get(process.env.VUE_APP_API_URL + "/api/").then(res => {
                this.$router.push({ path: "/" });
            });
        }
    },

    methods: {
        onSubmit() {
            this.$validator.validate().then(valid => {
                if (valid) {
                    this.loading = true;
                    this.errors.clear();
                    this.error = null;
                    axios.post(process.env.VUE_APP_API_URL + '/api/user/forgot-password', {
                        email: this.email
                    })
                        .then(response => {
                            if(response.data.error !== undefined){
                                this.error = response.data.error;
                                this.loading = false

                            }else if(response.data.success !== undefined){
                                this.$store.dispatch(
                                    "setMessage",
                                    response.data.success
                                );
                                this.goToLogin();
                                this.loading = false

                            }
                        })
                        .catch(error => {
                            this.loading = false;
                            if (error.response.status !== 422) {
                                this.error = error;
                            }
                            this.$setLaravelValidationErrorsFromResponse(error.response.data);
                        });
                }
            });
        },
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        goToRegisterPage() {
            if (this.$route.query.redirect_to) {
                this.$router.push('/register?redirect_to=' + this.$route.query.redirect_to)
            } else {
                this.$router.push('/register')
            }
        },
        goToLogin(){
            if(this.$route.query.redirect_to){
                this.$router.push("/login?redirect_to=" + this.$route.query.redirect_to);
            }else {
                this.$router.push("/login");
            }
        },
        messages(response) {
            return response.data.entities
                .filter(function (entity) {
                    return entity.rel.includes("status-messages");
                })[0];
        }
    }

}
</script>

<style scoped>

</style>
